import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import Menu from './menu'
import Footer from './footer'

export default function Layout({ location, title, children }) {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 3375) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <main className="grid grid-rows-layout h-full mx-0">
      <Img
        className="grad1"
        fluid={data.banner.childImageSharp.fluid}
        fadeIn={true}
      />
      <Menu selected={location.pathname} />
      <div className="container m-auto px-2 relative">
        <h1 className="my-5 text-lg font-bold uppercase border-b border-solid border-one-500 pb-2">
          {title}
        </h1>
        {children}
      </div>
      <Footer />
    </main>
  )
}

import React from 'react'
import { Link } from 'gatsby'

export default function Menu({ selected, item }) {
  const baseClass =
    'block lg:mr-10 p-2 lg:inline-block lg:border-none lg:my-2 lg:rounded text-one-200 uppercase lg:text-lg first:border-t border-b border-gray-800'
  const className = item.link === selected ? ' bg-red-700' : ''
  return (
    <Link key={item.name} className={baseClass + className} to={item.link}>
      {item.name}
    </Link>
  )
}

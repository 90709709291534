import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import MenuItem from './menu-item'

export default function Menu({ selected }) {
  const data = useStaticQuery(graphql`
    query {
      menu: allMenuItem {
        nodes {
          link
          name
        }
      }
    }
  `)

  const [hidden, setHidden] = useState('hidden')

  return (
    <nav className="m-auto flex w-full items-center justify-between flex-wrap text-center bg-gray-800 shadow-lg">
      <div className="block lg:hidden">
        <button
          onClick={() => setHidden(hidden ? '' : 'hidden')}
          className="flex items-center m-2 px-3 py-2 border rounded text-one-100 border-text-red-700 hover:text-red-700"
        >
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div
        className={
          'w-full block flex-grow lg:flex lg:items-center lg:w-auto ' + hidden
        }
      >
        <div className="text-sm lg:flex-grow">
          {data.menu.nodes.map((menu, key) => (
            <MenuItem key={key} selected={selected} item={menu} />
          ))}
        </div>
      </div>
    </nav>
  )
}
